import { post } from '@/utils/request';

// 获取汇报列表
const getListReq = (params) => post({
  url: '/store/oa.Notice/list',
  data: {
    ...params,
  },
});

// 公告和汇报详情
const getDetailReq = (notice_id) => post({
  url: '/store/oa.Notice/detail',
  data: {
    notice_id
  },
});

export {
  getListReq,
  getDetailReq,
};
