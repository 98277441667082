<template>
  <div>
    <div class="tab">
      <el-tabs v-model="form.notice_type" @tab-click="handleClick">
        <el-tab-pane label="日报" name="20"></el-tab-pane>
        <el-tab-pane label="周报" name="30"></el-tab-pane>
        <el-tab-pane label="月报" name="40"></el-tab-pane>
      </el-tabs>
    </div>
    <div style="margin-top: 10px;">
      <div class="card" style='display: flex;'>
        <div class="left">
          <el-button type='primary' plain @click='handleExport' icon='el-icon-upload2'>导出</el-button>
        </div>
        <div class='line'></div>
        <div class="middle">
          <el-form :model="form" :inline="true">
            <el-form-item label="汇报时间">
              <el-date-picker
                v-model="form.create_time"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="汇报人门店">
              <el-select v-model="form.hierarchy_id" placeholder="请选择门店" multiple filterable collapse-tags clearable>
                <template v-for="item in merchantList">
                  <el-option :label="item.hierarchy_name" :value="item.hierarchy_id" :key="item.hierarchy_id"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="汇报人">
              <el-input placeholder="请输入汇报人" @keydown.enter.native="onSubmit" v-model="form.from_realname" clearable></el-input>
            </el-form-item>
            <el-form-item label="汇报对象">
              <el-input placeholder="请输入汇报对象" @keydown.enter.native="onSubmit" v-model="form.to_realname" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button  @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="table">
        <el-table
          :data="tableData.list"
          style="width: 100%"
          stripe
          height="650"
          v-loading="loading"
          @row-dblclick="handleDalclick"
        >
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width">
              <template slot-scope="scope">
                {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
              </template>
            </el-table-column>
          </template>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleLook(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="$store.state.pageSizes"
          :page-size="$store.state.pageSizes[0]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total">
        </el-pagination>
      </div>
      <select-department :dialogVisible="dialogVisible" @onHideDepartment="handleHideDepartment" @onSelect="handleSelect" />
      <el-dialog
        title="详情"
        :visible.sync="lookVisible"
        width="40%"
        :before-close="handleClose">
        <div class="list">
          <div class="item">
            <div class="item-left">今日工作：</div>
            <div class="item-right">{{detail.content}}</div>
          </div>
          <div class="item">
            <div class="item-left">明日计划：</div>
            <div class="item-right">{{detail.plan}}</div>
          </div>
          <div class="item">
            <div class="item-left">其他事项：</div>
            <div class="item-right">{{detail.remark}}</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleClose">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getMerchantListReq } from '@/api/authority/config';
import { getListReq, getDetailReq } from '@/api/work/report';
import SelectDepartment from '../SelectDepartment.vue';

export default {
  data() {
    return {
      detail: {},
      loading: false,
      merchantList: [],
      currRow: {}, // 当前行
      lookVisible: false, // 查看弹窗出现标识
      activeName: 'first',
      label: '',
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      form: {
        receive_type: 30,
        notice_type: '20',
      },
      dialogVisible: false,
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '汇报时间', field_alias: 'create_time' },
        { field_text: '汇报人', field_alias: 'from_realname' },
        { field_text: '汇报人门店', field_alias: 'hierarchy_id_text' },
        { field_text: '汇报对象', field_alias: 'to_realname' },
      ],
      tableData: {
        list: [],
        total: 0,
      },
    };
  },
  components: {
    SelectDepartment,
  },
  created() {
    this.getMerchantList();
    this.getList();
  },
  methods: {
    handleDalclick(row) {
      this.handleLook(row);
    },
    resetForm() {
      this.form = {
        receive_type: this.form.receive_type,
        notice_type: this.form.notice_type,
      };
      this.page = 1;
      // this.limit = 15;
      this.getList();
    },
    // 查看详情
    getDetail(notice_id) {
      getDetailReq(notice_id)
        .then((res) => {
          if (res.code === 1) {
            this.detail = res.data;
          }
        });
    },
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    getMerchantList() {
      getMerchantListReq()
        .then((res) => {
          if (res.code === 1) {
            this.merchantList = res.data;
          }
        });
    },
    getList() {
      this.loading = true;
      getListReq({ ...this.form, page: this.page, page_num: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClick() {
      this.getList();
    },
    handleLook(row) {
      this.lookVisible = true;
      this.currRow = row;
      this.getDetail(row.notice_id);
    },
    // 弹窗关闭
    handleClose() {
      this.lookVisible = false;
    },
    // 选择部门子组件回调事件
    handleSelect(nodeList) {
      console.log(nodeList);
      if (nodeList.length === 1) {
        this.label = nodeList[0].label;
      } else {
        this.label = `${nodeList[0].label}等多个部门`;
      }
      this.form.select = 1;
    },
    handleHideDepartment() {
      this.dialogVisible = false;
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 分页条数改变
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getList();
    },
    // 导出
    handleExport() {},
    // 选择部门
    handleChoose() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item {
  margin-bottom: 0;
}
.list {
  .item {
    display: flex;
    margin-bottom: 20px;
    &-left {
      width: 100px;
      font-weight: bold;
    }
    &-right {
      flex: 1;
    }
  }
}
/deep/ .el-tabs__nav-wrap::after {
  content: '';
  height: 1px;
}
.tab {
   background: white;
  border-radius: 6px;
  border: 1px solid #ddd;
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  padding-left: 50px;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
  background: #ddd;
}
/deep/ .el-tabs__item {
  font-size: 16px;
  height: 60px;
  line-height: 60px;
}
</style>
